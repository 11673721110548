import React from 'react';
import loginStore from '../reducers/auth';

const Home = () => {
    const store = loginStore.getState();

    return (
        <div id="wrapper">
            <div className='row'>
                <div className='col-12 align-self-center'>
                    <h3>Bienvenido, {store.data.first_name} {store.data.last_name}</h3>
                </div>
            </div>
            <hr />
        </div>
    );
};

export default Home;