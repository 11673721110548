import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';

class Sidebar extends Component {
    componentDidMount() {
        $(".js__accordion").each(function(){
            var selector = $(this);
            selector.find(".js__control").on("click",function(event){
                event.preventDefault();
                if ($(this).parent().hasClass("active")){
                    $(this).parent().removeClass("active");
                    $(this).next().stop().slideUp(400);
                }else{
                    selector.find(".active").children(".js__content").stop().slideUp(400);
                    selector.find(".active").removeClass("active");
                    $(this).parent().addClass("active");
                    $(this).next(".js__content").slideDown(400);
                }
            });
        });

        $(".js__menu_mobile").on("click",function(){
            $("html").toggleClass("menu-active");
            $(window).trigger("resize");
        });
        
        $(".js__menu_close").on("click",function(){
            $("html").removeClass("menu-active");
        });

        $("body").on("click",function(event){
            if ($("html.menu-active").length && $(window).width() < 800){
                var selector = $(event.target);
                if (!(selector.hasClass("main-menu") || selector.hasClass("js__menu_mobile") || selector.parents(".main-menu").length || selector.parents(".js__menu_mobile").length)){
                    $("html").removeClass("menu-active");
                }
            }
        });
    }

    render() {
        return (
            <section>
                <div className="main-menu">
                    <header className="header">
                        <NavLink to='/' className="logo">ML Clasificados</NavLink>
                        <button type="button" className="button-close fa fa-times js__menu_close"></button>
                        <div className="user">
                            <div className="avatar">
                                {
                                    this.props.user && this.props.user.thumbnail && this.props.user.thumbnail.picture_url
                                    ?
                                    <img src={this.props.user.thumbnail.picture_url} alt="Profile" />
                                    :
                                    <div className="admin-pic-placeholder bg-info d-flex">
                                        <i className="far fa-grin-stars justify-content-center align-self-center" />
                                    </div>
                                }
                                <span className="status online"></span>
                            </div>
                            <h5 className="name">{this.props.user && this.props.user.first_name} {this.props.user && this.props.user.last_name}</h5>
                            <h5 className="position">{this.props.user && this.props.user.nickname}</h5>
                        </div>
                    </header>
                    <div className="content">
                        <div className="navigation">
                            <h5 className="title">Editor</h5>
                            <ul className="menu js__accordion">
                                <li>
                                    <NavLink to="/" exact activeClassName="current">
                                        <i className="menu-icon fas fa-home"></i>
                                        <span>Inicio</span>
                                    </NavLink>
                                </li>
                                <li>
                                    {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                    <a className="parent-item js__control" href="#">
                                        <i className="menu-icon fas fa-motorcycle"></i>
                                        <span>Motos</span>
                                        <span className="menu-arrow fa fa-angle-down"></span>
                                    </a>
                                    <ul className="sub-menu js__content">
                                        <NavLink to="/motos" exact>Descargar Motos Activas</NavLink>
                                        <NavLink to="/motos/editar" exact>Editar Motos Activas</NavLink>
                                    </ul>
                                </li>
                            </ul>
                            <h5 className="title">Utilidades</h5>
                            <ul className="menu js__accordion">
                                <li>
                                    <NavLink to="/ubicaciones" exact activeClassName="current">
                                        <i className="menu-icon fas fa-location-arrow"></i>
                                        <span>Ubicaciones</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Sidebar;