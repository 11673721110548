import axios from 'axios';
const CancelToken = axios.CancelToken;
export let cancel;

const URL = 'https://meli-classifieds.carbon-base.com/v1/locations';

export const getCountries = async () => {
    try {
        let request = await axios.get(URL + '/country',
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
};

export const getCountry = async (id) => {
    try {
        let request = await axios.get(URL + `/countries/${id}`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
};

export const getState = async (id) => {
    try {
        let request = await axios.get(URL + `/states/${id}`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
};

export const getCity = async (id) => {
    try {
        let request = await axios.get(URL + `/cities/${id}`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
};

export const getNeighborhood = async (id) => {
    try {
        let request = await axios.get(URL + `neighborhoods/${id}`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
};
