import React from 'react';
import loginStore from '../reducers/auth';
import {Redirect} from 'react-router-dom';

const Login = (props) => {

    const submit = async (event) => {
        window.location.href = 'https://auth.mercadolibre.com.ar/authorization?response_type=code&client_id=3561642789702411&redirect_uri=https://meli-classifieds.carbon-base.com/auth';
        return;
    }

    return (
        loginStore.getState().isAuthenticated
        ?
        <Redirect to="/" />
        :
        <div id="single-wrapper">
            <div className="frm-single">
                <div className="inside">
                    <div className="title"><strong>ML</strong>Clasificados</div>
                    <div className="frm-title">Login</div>
                    <button type="button" onClick={submit} className="frm-submit">Ingresar con MercadoLibre <i className="fa fa-arrow-circle-right" /></button>
                    <div className="frm-footer">Carbonbase © 2022.</div>
                </div>
            </div>
        </div>
    );
}

export default Login;