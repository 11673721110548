import { createStore } from 'redux';

let initialState = {};

if (localStorage.AUTH) {
  initialState = JSON.parse(localStorage.getItem('AUTH'));
} else {
  //debug
  initialState = {}
}

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      state = {
        isAuthenticated: true,
        user: action.payload.user_id,
        data: action.payload.ml_data,
        logging: true,
      }
      localStorage.setItem('AUTH', JSON.stringify(state));
      return state;
    case 'LOGOUT':
      state = {
        isAuthenticated: false,
        user: '',
        data: {},
      };
      localStorage.removeItem('AUTH');
      return state;

    default:
      return state;
  };
};

let loginStore = createStore(authentication);

export default loginStore;