import React, { useState } from 'react';
import swal from '@sweetalert/with-react';
import { exportData } from '../services/mercadolibre';

const VehicleDownload = () => {
    const [error, setError] = useState(false);
    
    const download = async () => {
        swal({   
            text: 'Ingresá tu email y te enviaremos la planilla cuando esté lista.',
            content: "input",
            icon: "info",
            buttons: { cancel: { text: 'Cancelar', value: false, visible: true, className: 'btn btn-danger' }, confirm: { text: 'Confirmar', value: true, className: 'btn btn-primary' } }
        })
        .then(async (email) => {
            if (email) {
                if (email.indexOf('@') === -1) {
                    return setError('Por favor, revisa tu email.')
                }

                try {
                    let req = await exportData({ email, type: 'motos' });
        
                    if (req.success) {
                        swal({   
                            title: "La planilla está en camino.",
                            content: (
                                <div>
                                    <div className="my-1">
                                        <p className="swal-text text-center pb-1">En unos minutos recibirás el archivo en la casilla {email}.</p>
                                    </div>
                                </div>
                            ),
                            icon: "success",
                            buttons: { confirm: { value: 'OK', className: 'btn btn-primary' } }
                        });
                    } else {
                        swal({   
                            title: "Ups, we can't provide that data.",   
                            text: "There is an error on your request. Please try again later or contact us.",   
                            icon: "error",
                            buttons: { confirm: { value: 'OK', className: 'btn btn-danger' } },
                            className: 'logout-alert'
                        });
                    }
                } catch(e) {
                    setError('Lo sentimos, hubo un error');
                }
            }
        })
    }

    return (
        <section>
            <div className='row'>
                <div className='col-md-6 col-xs-12 align-self-center'><h3>Descargar Motos</h3></div>
                <div className='col-md-6 col-xs-12 text-right'>
                    <button onClick={download} className="btn btn-icon btn-icon-left btn-success">
                        <i className="ico fas fa-download" /> Generar Planilla
                        </button>
                </div>
            </div>
            <hr />
            <p className="text-danger">{error}</p>
        </section>
    )
}

export default VehicleDownload;