import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import loginStore from '../reducers/auth';
import $ from 'jquery';
import swal from 'sweetalert';

class NavBar extends Component {
    constructor(props) {
        super(props);
        
        this.state = loginStore.getState();

        this.logout = () => {
            swal({   
                title: "Logout?",   
                text: "Are you sure you want to logout?",   
                icon: "warning",  
                buttons: ['No, stay plx!', 'Yes, I\'m out!'], 
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            }).then(
                (value) => {
                    if (value) {
                        loginStore.dispatch({ type: 'LOGOUT' })
                    }
                }
            );
        }
    }

    componentDidUpdate() {
        $(".js__drop_down_button").on("click",function(){
            if ($(window).width() > 1025){
                $(".js__drop_down").toggleClass("active");
            }
        });
    }

    render() {
        return (
            <div className="fixed-navbar">
                <div className="float-left">
                    <button type="button" className="menu-mobile-button fas fa-bars js__menu_mobile"></button>
                    <h1 className="page-title">{this.props.title}</h1>
                </div>
                <div className="float-right">
                    <a href="mailto:soporte@carbon-base.com" className="ico-item notice-alarm"><i className=" fa fa-envelope" /></a>
                    <span className="ico-item fa fa-power-off js__logout" onClick={this.logout}></span>
                </div>
            </div>
        );
    }
}

export default withRouter(NavBar);