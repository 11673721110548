import React, { Component } from 'react';

class Footer extends Component {

  render() {
    return (
        <footer className="footer">
            <ul className="list-inline">
              <li className="list-inline-item">2022 © Carbonbase</li>
              <li className="list-inline-item"><a href="mailto:soporte@carbon-base.com">Soporte.</a></li>
            </ul>
        </footer>
      );
  };
}

export default Footer;
