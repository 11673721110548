import React, { Component } from 'react';
import NavBar from './NavBar';
import SideBar from './SideBar';
import Footer from './Footer';
import loginStore from '../reducers/auth';

class AppContainer extends Component {
    constructor(props) {
        super(props);
        
        this.state = loginStore.getState();
    }

    render() {
        return (
            <main>
                <NavBar />
                <SideBar user={this.state.data} />
                <div id="wrapper">
                    <div className="main-content">
                        {this.props.children}
                        <Footer />
                    </div>
                </div>
            </main>
        );
    }
}

export default AppContainer;