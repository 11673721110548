import React, { useEffect, useState } from 'react';
import loginStore from '../reducers/auth';
import { login } from '../services/mercadolibre';
import { Redirect } from 'react-router-dom';
import Loader from './Loader';

const Auth = (props) => {
    const [error, setError] = useState(false);

    const getToken = async (code) => {
        if (code) {
            try {
                const result = await login(code);

                if (result.user_id) {
                    loginStore.dispatch({ type: 'LOGIN', payload: result });
                    props.history.push('/')
                }
            } catch (error) {
                setError(true);
            }
        } else {
            setError(true);
        }
    }

    useEffect(() => {
        const code = props.location.search.split('=')[1];
        getToken(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location.search])

    if (!error) {
        return (
            <Loader />
        ) 
    } else {
        return <Redirect to='/login' /> 
    }
}

export default Auth;