import React, { useState, useEffect } from 'react';
import Loader from './Loader';
import { getCountries, getCountry, getState, getCity, cancel } from '../services/locations';

const Locations = (props) => {
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);
    const [selected, setSelected] = useState({});
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const start = async () => {
            try {
                const r = await getCountries();
                setCountries(r);
                setLoader(false);
            } catch (e) {
                setLoader(false);
            }            
        }

        start();

        return function cleanup() {
            cancel();
        }
    }, []);

    const stateOptions = async (country) => {
        setSelected(value => ({...value, country}));
        const r = await getCountry(country);
        setStates(r.states);
        setCities([]);
        setNeighborhoods([]);
    };

    const cityOptions = async (state) => {
        setSelected(value => ({...value, state, city: '', neighborhood: ''}));
        const r = await getState(state);
        setCities(r.cities);
        setNeighborhoods([]);
    };

    const neighborhoodOptions = async (city) => {
        setSelected(value => ({...value, city, neighborhood: ''}));
        const r = await getCity(city);
        setNeighborhoods(r.neighborhoods);
    };

    const neighborhoodCode = (event) => {
        const {value} = event.target;
        console.log(value);
        setSelected(v => ({...v, neighborhood: value}));
    }

    return (
        <section>
            {
                loader && <Loader />
            }
            <div className='row'>
                <div className='col-12 align-self-center'><h3>Ubicaciones</h3></div>
            </div>
            <hr />
            <div className="box-content card white">
                <h4 className="box-title">Obtén el código de ubicación</h4>
                <div className="card-content">
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <div className='form-group'>
                                <label htmlFor="country">Selecciona un país</label>
                                <select id="country" name="country" defaultValue='' className="form-control" onChange={(v) => stateOptions(v.target.value)}>
                                <option value='' disabled>Selecciona una opción</option>
                                    {
                                        countries.map((c) => <option value={c.id} key={c.id}>{c.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className='form-group'>
                                <label htmlFor="countryid">Código de País</label>
                                <input name="countryid" className="form-control" value={selected.country} readOnly />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <div className='form-group'>
                                <label htmlFor="states">Selecciona una Provincia o Estado</label>
                                <select id="states" name="states" defaultValue='' className="form-control" onChange={(v) => cityOptions(v.target.value)} disabled={states.length === 0}>
                                    <option value='' disabled>Selecciona una opción</option>
                                    {
                                        states.map((c) => <option value={c.id} key={c.id}>{c.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className='form-group'>
                                <label htmlFor="stateid">Código de Provincia</label>
                                <input name="stateid" className="form-control" value={selected.state} readOnly />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <div className='form-group'>
                                <label htmlFor="city">Selecciona una ciudad</label>
                                <select id="city" name="city" defaultValue='' className="form-control" onChange={(v) => neighborhoodOptions(v.target.value)} disabled={cities.length === 0}>
                                    <option value='' disabled>Selecciona una opción</option>
                                    {
                                        cities.map((s) => <option key={s.id} value={s.id}>{s.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className='form-group'>
                                <label htmlFor="cityid">Código de Ciudad</label>
                                <input name="cityid" className="form-control" value={selected.city} readOnly />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <div className='form-group'>
                                <label htmlFor="neighborhoods">Selecciona una localidad</label>
                                <select id="neighborhoods" name="neighborhoods" defaultValue='' className="form-control" onChange={neighborhoodCode} disabled={neighborhoods.length === 0}>
                                    <option value='' disabled>Selecciona una opción</option>
                                    {
                                        neighborhoods.map((s) => <option key={s.id} value={s.id}>{s.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className='form-group'>
                                <label htmlFor="neighborhoodid">Código de Localidad</label>
                                <input name="neighborhoodid" className="form-control" value={selected.neighborhood} readOnly />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Locations;