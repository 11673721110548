import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import Loader from './Loader';
import swal from '@sweetalert/with-react';
import { updateData } from '../services/mercadolibre' 

const VehicleEdit = (props) => {
    const [file, setFile] = useState(false);
    const [error, setError] = useState(false);
    const [loader, setLoader] = useState(false);

    const submit = async () => {
        setLoader(true);

        swal({   
            text: 'Ingresá tu email y te notificaremos cuando los cambios estén aplicados.',
            content: "input",
            icon: "info",
            buttons: { cancel: { text: 'Cancelar', value: false, visible: true, className: 'btn btn-danger' }, confirm: { text: 'Confirmar', value: true, className: 'btn btn-primary' } }
        })
        .then(async (email) => {
            if (email) {
                try {
                    const req = await updateData({file, email});
                    setLoader(false);
                    if (req.success) {
                        swal({   
                            title: "Estamos procesando los cambios.",
                            content: (
                                <div>
                                    <div className="my-1">
                                        <p className="swal-text text-center pb-1">En unos minutos recibirás los detalles en la casilla {email}.</p>
                                    </div>
                                </div>
                            ),
                            icon: "success",
                            buttons: { confirm: { value: 'OK', className: 'btn btn-primary' } }
                        });
                    } else {
                        swal({   
                            title: "Ups, hubo un error.",   
                            text: "Hubo un problema con tu solicitud. Por favor, intentalo nuevamente o ponte en contacto con nosotros.",   
                            icon: "error",
                            buttons: { confirm: { value: 'OK', className: 'btn btn-danger' } },
                            className: 'logout-alert'
                        });
                    }
                } catch (e) {
                    setLoader(false);
                    setError('Lo sentimos, hubo un error');
                }
            } else {
                setLoader(false);
                setError('Debes ingresar un email para continuar.');
            }
        });
    };

    return (
        <section>
            {
                loader && <Loader />
            }
            <div className='row'>
                <div className='col-12 align-self-center'><h3>Editar Motos</h3></div>
            </div>
            <hr />
            <div className="box-content card white">
                <h4 className="box-title">Subir Planilla con Datos</h4>
                <div className="card-content">
                    <div className="row">
                        <div className="col-12">
                        <div className='form-group'>
                            <label htmlFor="raw">Agregá tu planilla.</label>
                            <div className="form-control">
                                <Dropzone
                                    multiple={false}
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onDrop={(acceptedFiles) => {
                                        acceptedFiles.forEach((file) => {
                                            if (!file) {
                                                return setError('Por favor, utiliza archivos .xlsx');
                                            }

                                            setFile(file);
                                        });
                                    }}>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <p><i className={ `${file ? 'fas' : 'far' } fa-file-excel mr-2`} />{ file ? file.name : 'Arrastrá tu archivo aquí.'}</p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-xs-12 margin-top-20">
                    <p className="danger">{error}</p>
                </div>
                <div className="col-lg-6 col-xs-12 text-right margin-top-20">
                    <button onClick={submit} className="btn btn-primary btn-md" disabled={!file}>Subir y editar</button>
                </div>
            </div>
        </section>
    )
}

export default VehicleEdit;