import React, { useState, useEffect } from 'react';
import { 
  BrowserRouter as Router,
  Route,
  Redirect
} from 'react-router-dom';
/* COMPONENTS */
import AppContainer from './components/AppContainer';
import Auth from './components/Auth';
import Home from './components/Home';
import Locations from './components/Locations';
import Login from './components/Login';
import VehicleDownload from './components/VehicleDownload';
import VehicleEdit from './components/VehicleEdit';
/* STORES */
import loginStore from './reducers/auth';

const App = () => {
  const [auth, setAuth] = useState(loginStore.getState());
  const [loggedIn, setLoggedIn] = useState(false);

  const unsubscribe = loginStore.subscribe(() => {
    setAuth(loginStore.getState())
  });

  useEffect(() => {
    setAuth(loginStore.getState())

    return function cleanup() {
      unsubscribe();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoggedIn(auth.isAuthenticated);
  }, [auth]);

  return(
    <Router>
      <div>
        <Route path='/auth' component={Auth} />
        <Route path='/login' component={Login} />
        {
          loggedIn
          ?
          <AppContainer>
            <Route path='/' exact component={Home} />
            <Route path='/motos' exact component={VehicleDownload} />
            <Route path='/motos/editar' component={VehicleEdit} />
            <Route path='/ubicaciones' component={Locations} />
          </AppContainer>
          :
          <Redirect to="/login" />
        }
      </div>
    </Router>
  );
};

export default App;