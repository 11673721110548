import axios from 'axios';
import loginStore from '../reducers/auth';
const CancelToken = axios.CancelToken;
export let cancel;

const URL = 'https://meli-classifieds.carbon-base.com/v1/api';

export const login = async (code) => {
    const url = `${URL}/login?code=${code}`;

    try {
        let request = await axios.get(url,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
}

export const exportData = async (options) => {
    const url = `${URL}/getFile`;
    const body = {
        ...options,
        id: loginStore.getState().user,
    }

    try {
        let request = await axios.post(url, body,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
}

export const updateData = async (options) => {
    const url = `${URL}/update`;
    const body = {
        ...options,
        id: loginStore.getState().user,
    }

    const formData = new FormData();

    for (let k in body) {
        formData.append(k, body[k]);
    }

    try {
        let request = await axios.post(url, formData,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
}